.Modal {
  position: fixed;
  height: 650px;
  max-height: 85%;
  width: 700px;
  max-width: 90%;
  // border: 2px solid black;
  border-radius: 4px;
  padding: 5px;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  color: #595959;

  outline: none;

  z-index: 2;

  @import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
  font-family: "Inconsolata", serif;

  @include desktop {
    height: 600px;
    max-height: 100%;
    width: 700px;
    max-width: 100%;
  }

  .modal-header {
    padding: 15px;
    width: 100%;
    height: 10%;
    border-bottom: 1px solid black;

    @include desktop {
      height: 15%;
    }

    .modal-title {
      font-size: 25px;
      // text-align: justify;
    }

    .modal-close {
      // margin-top: 1px;
      margin-right: 5px;
      position: absolute;
      top: 0;
      right: 0;

      background: transparent;
      border: none;
      font-size: 25px;

      // border: 1px solid black;
      // border-radius: 5px;
      padding: 0px 5px;

      &:hover {
        opacity: 60%;
      }
    }
  }
}

.Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.65);

  z-index: 2;
}
