.logo-info-wrapper {
  width: 100%;
  min-height: calc(100vh - 40px);

  text-align: center;

  background-color: #121212;
  color: white;

  justify-content: center;

  @include desktop {
    font-size: 1.5em;

    min-height: calc(100vh - 50px);
  }

  .logo {
    width: 100%;
    height: auto;
    background-color: transparent;
    filter: drop-shadow(0 0 0.75rem red);

    @include desktop {
      width: 30%;
      max-width: 100%;
    }
  }

  .info {
    padding: 1em;
    margin-bottom: 20px;

    font-size: 1.1em;
    text-align: center;

    p {
      margin: 7px 0;
    }
  }

  .downArrow {
    // position: absolute;
    // bottom: 0%;
    // left: calc((100vw / 2) - 20px);

    @include desktop {
      position: absolute;
      bottom: 0%;
      left: calc((100vw / 2) - 20px);
    }

    @media (max-height: 730px) {
      display: none;
    }

    img {
      filter: invert(100%);
    }
  }
  .bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
  }
  @-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
}
