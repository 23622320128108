.mobileLandingContainer {
  width: 100%;
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }

  > .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    justify-content: center;

    #app-store-badge {
      margin: 0 auto;
      overflow: hidden;
      border-radius: 13px;
      width: 250px;
      height: 83px;

      img {
        border-radius: 13px;
        width: 250px;
        height: 83px
      }
    }

    #play-store-badge {
      img {
        width: 288px
      }
    }

    #kos-app-screenshot {
      min-width: 200px;
      max-width: 300px;
    }
  }
}
