.login-form-wrapper {
  display: flex;
  justify-content: center;
  padding: 30px;

  .login-form {
    min-width: 300px;
    width: 35vw;
    height: 40%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 20px;

    .login-form-header {
      display: flex;
      justify-content: center;

      p {
        font-size: 26px;
        font-weight: 600;

        @include desktop {
          font-size: 3em;
        }
      }
    }

    .form-input-wrapper {
      width: 100%;
      margin-top: 10px;
      display: grid;

      .form-input {
        height: 30px;
        margin-bottom: 0.5rem;
        width: 60%;

        border: none;

        background: whitesmoke;

        outline: none;
        text-align: center;

        @include desktop {
          font-size: 1.5em;

          height: 50px;
        }
      }



      .bottom-border-animation {
        border: 1px solid transparent;
        border-radius: 3px;
        width: 100%;
        transition: ease-in-out 1s;

        &:focus,
        &:hover {
          border-bottom: 1px solid black;
        }
      }
    }

    .form-checkbox-wrapper{
      height: 30px;
      margin: 20px 20px 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      label{
        font-size: 20px;
      }
      input{
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }
    
    }

    .btn-wrapper {
      width: 100%;
      margin-top: 10px;

      display: grid;
      justify-items: center;
      .btn {
        margin: 1em 6em;
        border: 1px solid transparent;
        border-radius: 3px;
        width: 50%;
        height: 30px;
        transition: ease-in-out 0.25s;
        cursor: pointer;

        @include desktop {
          font-size: 1.5em;
          height: 50px;
        }

        &:hover {
          background-color: rgb(42, 35, 35);
          color: white;
        }

        &:focus,
        &:active {
          transition: 0.25s;
          outline: none;
          box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid black;
        }
      }
    }
  }
}
