.navbar {
  height: 55px;
  color: white;
  background-color: #080506;
  position: sticky;
  top: 0;
  z-index: 1;

  .navigationContainer {
    .nav-menuburger {
      display: none;

      @media (max-width: 800px) {
        display: flex;
        position: absolute;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        padding: 1px;
      }
    }

    .navlink-container {
      .left {
        display: flex;
        justify-content: space-evenly;
        padding: 10px 0px;

        .navlink-wrapper {
          @media (max-width: 800px) {
            display: none;
          }

          .link {
            color: inherit;
            text-decoration: none;
            border-bottom: 1px solid transparent;
          }

          .active {
            border-bottom: 1px solid whitesmoke;
          }

          .logout-btn {
            outline: none;
            border: none;
            background: black;
            cursor: pointer;
            border: 1px solid white;
            border-radius: 10px;
            font-size: 14px;
            padding: 4px;
            transition: ease-in-out 0.25s;

            &:hover {
              background-color: white;
              color: black;
              box-shadow: 0px 0px 9px 3px white;
            }
          }
        }
      }

      .logged-out {
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 800px) {
          justify-content: start;
        }

        .nav-btn-menu-burger {
          display: none;

          @media (max-width: 800px) {
            display: flex;
            position: absolute;
            align-items: center;

            transition: 300ms;

            .icon-closed {
              font-size: 2em;
              font-weight: 500;

              &:hover {
                transition: 300ms;
              }
            }

            .icon-burger-menu {
              font-size: 2em;
              font-weight: 500;

              &:hover {
                transition: 300ms;
              }

              &::before {
                transform: translateX(-50px);
                transition: 300ms;
              }
            }
          }
        }
      }

      .logged-in {
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 800px) {
          justify-content: start;
        }

        .Profile-Navegation {
          border: none;

          @media (max-width: 800px) {
            display: none;
          }
        }

        .nav-btn-menu-burger {
          display: none;

          @media (max-width: 800px) {
            display: flex;
            position: absolute;
            align-items: center;
            transition: 300ms;

            .icon-closed {
              font-size: 2.5em;
              font-weight: 500;

              &:hover {
                transition: 300ms;
              }
            }

            .icon-burger-menu {
              font-size: 2em;
              font-weight: 500;

              &:hover {
                transition: 300ms;
              }

              &::before {
                transform: translateX(-50px);
                transition: 300ms;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .navlink-container-menu {
      filter: opacity(95%);
      display: flex;
      flex-direction: column;
      justify-content: start;
      background: #080506;
      width: 360px;
      height: 100vh;
      padding: 10px;
      margin-top: 45px;
      border-radius: 5px;
      transition: all 0.5s ease;

      .left {
        .navlink-wrapper {
          padding-top: 20px;
          font-size: 1.9em;

          .link {
            color: inherit;
            text-decoration: none;
            border-bottom: 1px solid transparent;
          }

          .active {
            border-bottom: 1px solid whitesmoke;
          }

          .logout-btn {
            outline: none;
            border: none;
            font-size: 1em;
            background-color: inherit;
            cursor: pointer;
            border: 1px solid white;
            border-radius: 10px;
            font-size: 18px;
            padding: 2px;
          }
        }
      }

      .logged-out {
        grid-template-columns: 1fr 1fr 1fr;

        .Profile-Navegation {
          display: none;
        }

        .nav-btn-menu-burger {
          display: none;
        }
      }

      .logged-in {
        grid-template-columns: 1fr 1fr 1fr;

        .Profile-Navegation {
          display: none;
        }

        .nav-btn-menu-burger {
          display: none;
        }
      }
    }
  }
}

@include desktop {
  .navbar {
    height: 60px;
    font-size: 1.5em;
    padding-top: 3px;
  }

  .logout-btn {
    font-size: inherit;
  }
}