.weightLossAdmin {
  .weightAndVideo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .weight {
      margin-left: 10px;
    }
  }

  .modalContent {
    .contentVideo {
      display: flex;
      justify-content: center;
      width: 100%;
      video {
        width: 100%;
        max-width: 640px;
        height: auto;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        background-color: #000;
      }
    }
  }
}
