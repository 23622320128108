.sppiner {
  display: flex;
  justify-content: center;
}

* {
  margin: 0;
  padding: 0;
  font-family: poppins;
}

html ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.logo-svg {
  width: 100%;
  height: 5vh;
}

.logo-png {
  height: 100%;
}

.homepage-container {
  min-height: calc(100vh - 40px);
  background-color: #121212;
  color: white;
  padding-bottom: 30px;
  font-family: "Poppins";
}
.homepage-container .logo-wrapper {
  width: 100%;
  text-align: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  .homepage-container .logo-wrapper {
    font-size: 1.5em;
  }
}
.homepage-container .logo-wrapper .logo {
  width: 100%;
  height: auto;
  background-color: transparent;
  filter: drop-shadow(0 0 0.75rem red);
}
@media (min-width: 1024px) {
  .homepage-container .logo-wrapper .logo {
    width: 30%;
    max-width: 100%;
  }
}
.homepage-container .box-rising {
  background-color: #ffffff;
  color: black;
  -webkit-transform: skewY(-5deg);
  -moz-transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -o-transform: skewY(-5deg);
  transform: skewY(-5deg);
}
.homepage-container .box-rising .wrapper {
  -webkit-transform: skewY(5deg);
  -moz-transform: skewY(5deg);
  -ms-transform: skewY(5deg);
  -o-transform: skewY(5deg);
  transform: skewY(5deg);
}
.homepage-container .box-falling {
  background-color: black;
  color: white;
  -webkit-transform: skewY(5deg);
  -moz-transform: skewY(5deg);
  -ms-transform: skewY(5deg);
  -o-transform: skewY(5deg);
  transform: skewY(5deg);
}
.homepage-container .box-falling .wrapper {
  -webkit-transform: skewY(-5deg);
  -moz-transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -o-transform: skewY(-5deg);
  transform: skewY(-5deg);
}
@media (min-width: 1024px) {
  .homepage-container .box-rising {
    background-color: #ffffff;
    color: black;
    -webkit-transform: skewY(-2.5deg);
    -moz-transform: skewY(-2.5deg);
    -ms-transform: skewY(-2.5deg);
    -o-transform: skewY(-2.5deg);
    transform: skewY(-2.5deg);
  }
  .homepage-container .box-rising .wrapper {
    -webkit-transform: skewY(2.5deg);
    -moz-transform: skewY(2.5deg);
    -ms-transform: skewY(2.5deg);
    -o-transform: skewY(2.5deg);
    transform: skewY(2.5deg);
  }
}
.homepage-container .box-falling {
  background-color: black;
  color: white;
  -webkit-transform: skewY(2.5deg);
  -moz-transform: skewY(2.5deg);
  -ms-transform: skewY(2.5deg);
  -o-transform: skewY(2.5deg);
  transform: skewY(2.5deg);
}
.homepage-container .box-falling .wrapper {
  -webkit-transform: skewY(-2.5deg);
  -moz-transform: skewY(-2.5deg);
  -ms-transform: skewY(-2.5deg);
  -o-transform: skewY(-2.5deg);
  transform: skewY(-2.5deg);
}
.homepage-container .box-rising,
.homepage-container .box-falling {
  margin: 17.5px 0;
  padding: 5% 20px; /* Added a percentage value for top/bottom padding to keep the wrapper inside of the parent */
}
@media (min-width: 1024px) {
  .homepage-container .box-rising,
  .homepage-container .box-falling {
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .homepage-container .box-rising .wrapper,
  .homepage-container .box-falling .wrapper {
    margin: 10px 20vw;
  }
}
.homepage-container .box-rising .wrapper .header,
.homepage-container .box-falling .wrapper .header {
  text-align: center;
}
.homepage-container .box-rising .wrapper p,
.homepage-container .box-rising .wrapper li,
.homepage-container .box-falling .wrapper p,
.homepage-container .box-falling .wrapper li {
  padding: 5px 0;
  margin: 3px;
  font-size: 1.2em;
  text-align: justify;
  list-style-position: inside;
}

.bold {
  font-weight: bold;
}

.registration-form {
  margin: 10px;
  margin-bottom: 0;
  min-height: 100vh;
  color: rgb(0, 0, 0);
}
@media (min-width: 1024px) {
  .registration-form {
    max-width: 85%;
    margin: auto;
    font-size: 1.5em;
    text-align: center;
  }
}
.registration-form .form-header {
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 1024px) {
  .registration-form .form-header {
    margin-top: 20px;
    padding-top: 20px;
  }
}
.registration-form .slidey-wrapper {
  display: grid;
}
@media (min-width: 1024px) {
  .registration-form .slidey-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
.registration-form .slidey-wrapper .personal-info,
.registration-form .slidey-wrapper .payment-info {
  display: grid;
  grid-gap: 2px;
  padding: 0.5em;
  margin: auto;
  width: 90%;
}
.registration-form .slidey-wrapper .personal-info .title,
.registration-form .slidey-wrapper .payment-info .title {
  text-align: justify;
}
@media (min-width: 1024px) {
  .registration-form .slidey-wrapper .personal-info .title,
  .registration-form .slidey-wrapper .payment-info .title {
    text-align: inherit;
  }
}
.registration-form .slidey-wrapper .personal-info .col,
.registration-form .slidey-wrapper .payment-info .col {
  justify-self: center;
  width: 80%;
}
.registration-form .slidey-wrapper .personal-info .col label,
.registration-form .slidey-wrapper .payment-info .col label {
  font-size: large;
}
.registration-form .slidey-wrapper .personal-info .col .labelErr,
.registration-form .slidey-wrapper .payment-info .col .labelErr {
  color: red;
}
.registration-form .slidey-wrapper .personal-info .col .inputErr,
.registration-form .slidey-wrapper .payment-info .col .inputErr {
  border: 1.5px solid red;
}
.registration-form .slidey-wrapper .personal-info .col input,
.registration-form .slidey-wrapper .personal-info .col select,
.registration-form .slidey-wrapper .payment-info .col input,
.registration-form .slidey-wrapper .payment-info .col select {
  width: 100%;
  height: 30px;
  padding: 3px;
  border: none;
  border-radius: 10px;
  margin: 0 auto 15px;
  display: block;
  background-color: #f3f3f4;
}
@media (min-width: 1024px) {
  .registration-form .slidey-wrapper .personal-info .col input,
  .registration-form .slidey-wrapper .personal-info .col select,
  .registration-form .slidey-wrapper .payment-info .col input,
  .registration-form .slidey-wrapper .payment-info .col select {
    height: 2.5em;
    font-size: 1.25rem;
  }
}
.registration-form .slidey-wrapper .personal-info .col select,
.registration-form .slidey-wrapper .payment-info .col select {
  font-size: inherit;
}
.registration-form .slidey-wrapper .personal-info .err,
.registration-form .slidey-wrapper .payment-info .err {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
  font-size: 1.1em;
}
.registration-form .slidey-wrapper .personal-info .totalPrice,
.registration-form .slidey-wrapper .payment-info .totalPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.registration-form .slidey-wrapper .payment-info .card-info {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 7px;
  text-align: justify;
}
.registration-form .slidey-wrapper .payment-info .card-info .card-element {
  justify-self: center;
  width: 80%;
  border-bottom: 1px solid black;
}
.registration-form .slidey-wrapper .payment-info .err {
  display: flex;
  justify-content: center;
  height: 21px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  color: red;
  font-size: 1.1em;
}
.registration-form .slidey-wrapper .payment-info .totalPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.registration-form .slidey-wrapper .payment-info .submit-button {
  display: flex;
  justify-content: center;
  margin: 5px;
  margin-top: 10px;
}
.registration-form .slidey-wrapper .payment-info .submit-button button {
  border: 1px solid transparent;
  background-color: black;
  color: #ffffff;
  height: 40px;
  width: 100px;
  transition: 0.3s ease;
}
.registration-form .slidey-wrapper .payment-info .submit-button button:hover {
  background-color: grey;
}
@media (min-width: 1024px) {
  .registration-form .slidey-wrapper .payment-info .submit-button button {
    height: 60px;
    width: 150px;
    font-size: inherit;
  }
}

.navbar {
  height: 55px;
  color: white;
  background-color: #080506;
  position: sticky;
  top: 0;
  z-index: 1;
}
.navbar .navigationContainer .nav-menuburger {
  display: none;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .nav-menuburger {
    display: flex;
    position: absolute;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 1px;
  }
}
.navbar .navigationContainer .navlink-container .left {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0px;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .navlink-container .left .navlink-wrapper {
    display: none;
  }
}
.navbar .navigationContainer .navlink-container .left .navlink-wrapper .link {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
.navbar .navigationContainer .navlink-container .left .navlink-wrapper .active {
  border-bottom: 1px solid whitesmoke;
}
.navbar .navigationContainer .navlink-container .left .navlink-wrapper .logout-btn {
  outline: none;
  border: none;
  background: black;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 14px;
  padding: 4px;
  transition: ease-in-out 0.25s;
}
.navbar .navigationContainer .navlink-container .left .navlink-wrapper .logout-btn:hover {
  background-color: white;
  color: black;
  box-shadow: 0px 0px 9px 3px white;
}
.navbar .navigationContainer .navlink-container .logged-out {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .navlink-container .logged-out {
    justify-content: start;
  }
}
.navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger {
  display: none;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger {
    display: flex;
    position: absolute;
    align-items: center;
    transition: 300ms;
  }
  .navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger .icon-closed {
    font-size: 2em;
    font-weight: 500;
  }
  .navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger .icon-closed:hover {
    transition: 300ms;
  }
  .navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger .icon-burger-menu {
    font-size: 2em;
    font-weight: 500;
  }
  .navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger .icon-burger-menu:hover {
    transition: 300ms;
  }
  .navbar .navigationContainer .navlink-container .logged-out .nav-btn-menu-burger .icon-burger-menu::before {
    transform: translateX(-50px);
    transition: 300ms;
  }
}
.navbar .navigationContainer .navlink-container .logged-in {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .navlink-container .logged-in {
    justify-content: start;
  }
}
.navbar .navigationContainer .navlink-container .logged-in .Profile-Navegation {
  border: none;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .navlink-container .logged-in .Profile-Navegation {
    display: none;
  }
}
.navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger {
  display: none;
}
@media (max-width: 800px) {
  .navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger {
    display: flex;
    position: absolute;
    align-items: center;
    transition: 300ms;
  }
  .navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger .icon-closed {
    font-size: 2.5em;
    font-weight: 500;
  }
  .navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger .icon-closed:hover {
    transition: 300ms;
  }
  .navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger .icon-burger-menu {
    font-size: 2em;
    font-weight: 500;
  }
  .navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger .icon-burger-menu:hover {
    transition: 300ms;
  }
  .navbar .navigationContainer .navlink-container .logged-in .nav-btn-menu-burger .icon-burger-menu::before {
    transform: translateX(-50px);
    transition: 300ms;
  }
}
@media (max-width: 800px) {
  .navbar .navlink-container-menu {
    filter: opacity(95%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    background: #080506;
    width: 360px;
    height: 100vh;
    padding: 10px;
    margin-top: 45px;
    border-radius: 5px;
    transition: all 0.5s ease;
  }
  .navbar .navlink-container-menu .left .navlink-wrapper {
    padding-top: 20px;
    font-size: 1.9em;
  }
  .navbar .navlink-container-menu .left .navlink-wrapper .link {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }
  .navbar .navlink-container-menu .left .navlink-wrapper .active {
    border-bottom: 1px solid whitesmoke;
  }
  .navbar .navlink-container-menu .left .navlink-wrapper .logout-btn {
    outline: none;
    border: none;
    font-size: 1em;
    background-color: inherit;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 18px;
    padding: 2px;
  }
  .navbar .navlink-container-menu .logged-out {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .navbar .navlink-container-menu .logged-out .Profile-Navegation {
    display: none;
  }
  .navbar .navlink-container-menu .logged-out .nav-btn-menu-burger {
    display: none;
  }
  .navbar .navlink-container-menu .logged-in {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .navbar .navlink-container-menu .logged-in .Profile-Navegation {
    display: none;
  }
  .navbar .navlink-container-menu .logged-in .nav-btn-menu-burger {
    display: none;
  }
}

@media (min-width: 1024px) {
  .navbar {
    height: 60px;
    font-size: 1.5em;
    padding-top: 3px;
  }
  .logout-btn {
    font-size: inherit;
  }
}
.logo-info-wrapper {
  width: 100%;
  min-height: calc(100vh - 40px);
  text-align: center;
  background-color: #121212;
  color: white;
  justify-content: center;
}
@media (min-width: 1024px) {
  .logo-info-wrapper {
    font-size: 1.5em;
    min-height: calc(100vh - 50px);
  }
}
.logo-info-wrapper .logo {
  width: 100%;
  height: auto;
  background-color: transparent;
  filter: drop-shadow(0 0 0.75rem red);
}
@media (min-width: 1024px) {
  .logo-info-wrapper .logo {
    width: 30%;
    max-width: 100%;
  }
}
.logo-info-wrapper .info {
  padding: 1em;
  margin-bottom: 20px;
  font-size: 1.1em;
  text-align: center;
}
.logo-info-wrapper .info p {
  margin: 7px 0;
}
@media (min-width: 1024px) {
  .logo-info-wrapper .downArrow {
    position: absolute;
    bottom: 0%;
    left: calc(50vw - 20px);
  }
}
@media (max-height: 730px) {
  .logo-info-wrapper .downArrow {
    display: none;
  }
}
.logo-info-wrapper .downArrow img {
  filter: invert(100%);
}
.logo-info-wrapper .bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.login-form-wrapper {
  display: flex;
  justify-content: center;
  padding: 30px;
}
.login-form-wrapper .login-form {
  min-width: 300px;
  width: 35vw;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.login-form-wrapper .login-form .login-form-header {
  display: flex;
  justify-content: center;
}
.login-form-wrapper .login-form .login-form-header p {
  font-size: 26px;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .login-form-wrapper .login-form .login-form-header p {
    font-size: 3em;
  }
}
.login-form-wrapper .login-form .form-input-wrapper {
  width: 100%;
  margin-top: 10px;
  display: grid;
}
.login-form-wrapper .login-form .form-input-wrapper .form-input {
  height: 30px;
  margin-bottom: 0.5rem;
  width: 60%;
  border: none;
  background: whitesmoke;
  outline: none;
  text-align: center;
}
@media (min-width: 1024px) {
  .login-form-wrapper .login-form .form-input-wrapper .form-input {
    font-size: 1.5em;
    height: 50px;
  }
}
.login-form-wrapper .login-form .form-input-wrapper .bottom-border-animation {
  border: 1px solid transparent;
  border-radius: 3px;
  width: 100%;
  transition: ease-in-out 1s;
}
.login-form-wrapper .login-form .form-input-wrapper .bottom-border-animation:focus, .login-form-wrapper .login-form .form-input-wrapper .bottom-border-animation:hover {
  border-bottom: 1px solid black;
}
.login-form-wrapper .login-form .form-checkbox-wrapper {
  height: 30px;
  margin: 20px 20px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form-wrapper .login-form .form-checkbox-wrapper label {
  font-size: 20px;
}
.login-form-wrapper .login-form .form-checkbox-wrapper input {
  margin-left: 10px;
  height: 20px;
  width: 20px;
}
.login-form-wrapper .login-form .btn-wrapper {
  width: 100%;
  margin-top: 10px;
  display: grid;
  justify-items: center;
}
.login-form-wrapper .login-form .btn-wrapper .btn {
  margin: 1em 6em;
  border: 1px solid transparent;
  border-radius: 3px;
  width: 50%;
  height: 30px;
  transition: ease-in-out 0.25s;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .login-form-wrapper .login-form .btn-wrapper .btn {
    font-size: 1.5em;
    height: 50px;
  }
}
.login-form-wrapper .login-form .btn-wrapper .btn:hover {
  background-color: rgb(42, 35, 35);
  color: white;
}
.login-form-wrapper .login-form .btn-wrapper .btn:focus, .login-form-wrapper .login-form .btn-wrapper .btn:active {
  transition: 0.25s;
  outline: none;
  box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid black;
}

.forgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: auto;
  margin-top: 5vh;
  border-radius: 15px;
  padding: 25px 15px;
  width: 50vw;
  min-width: 300px;
  height: 30vh;
  min-height: 300px;
  background-color: whitesmoke;
}
.forgotPassword h2 {
  text-align: center;
  background-color: white;
  padding: 7.5px;
  border-radius: 15px;
}
.forgotPassword input {
  margin: 15px;
  width: 65%;
  height: 35px;
  outline: none;
  border: none;
  border-radius: 5px;
  text-align: center;
  border-bottom: 1px solid black;
}
.forgotPassword button {
  width: 75px;
  height: 30px;
  margin: 10px auto;
  border-radius: 10px;
}
.forgotPassword a {
  margin: auto;
  font-size: 22px;
}

.announcements-container {
  padding: 5px;
}
.announcements-container .accordion-container .accordion-tab {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 10px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgb(236, 236, 236);
  opacity: 1;
  transition: box-shadow 0.2s, opacity 0.4s;
}
.announcements-container .accordion-container .accordion-tab:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.11);
}
.announcements-container .accordion-container .accordion-tab .accordion-input {
  display: none;
}
.announcements-container .accordion-container .accordion-tab .accordion-input:checked ~ .accordion-content + .accordion-tab-content {
  max-height: 3000px;
}
.announcements-container .accordion-container .accordion-tab .accordion-input:checked ~ .accordion-content:after {
  transform: rotate(0);
}
.announcements-container .accordion-container .accordion-tab .accordion-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 70px;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  z-index: 1;
  cursor: pointer;
}
.announcements-container .accordion-container .accordion-tab .accordion-label:hover ~ .accordion-content:after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiM1NTZBRUEiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=");
}
@media screen and (max-width: 800px) {
  .announcements-container .accordion-container .accordion-tab .accordion-label {
    max-height: 80px;
  }
}
@media screen and (max-width: 500px) {
  .announcements-container .accordion-container .accordion-tab .accordion-label {
    max-height: 90px;
  }
}
.announcements-container .accordion-container .accordion-tab .accordion-content {
  position: relative;
  display: block;
  height: 80px;
  margin: 0;
  padding: 0 87px 0 30px;
  font-size: 0;
  white-space: nowrap;
  cursor: pointer;
}
.announcements-container .accordion-container .accordion-tab .accordion-content:before, .announcements-container .accordion-container .accordion-tab .accordion-content:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
}
.announcements-container .accordion-container .accordion-tab .accordion-content:before {
  height: 100%;
}
.announcements-container .accordion-container .accordion-tab .accordion-content:after {
  position: absolute;
  width: 24px;
  height: 100%;
  right: 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNBOUFDQUYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}
@media screen and (max-width: 800px) {
  .announcements-container .accordion-container .accordion-tab .accordion-content {
    height: auto;
    padding: 10px 87px 10px 30px;
  }
  .announcements-container .accordion-container .accordion-tab .accordion-content:before {
    display: none;
  }
  .announcements-container .accordion-container .accordion-tab .accordion-content:after {
    top: 0;
  }
}
.announcements-container .accordion-container .accordion-tab .accordion-content .accordion-title {
  display: inline-block;
  width: 75%;
  margin-left: 16px;
  font-weight: 500;
  color: #242a32;
  white-space: normal;
  text-align: left;
  vertical-align: middle;
  text-transform: capitalize;
}
.announcements-container .accordion-container .accordion-tab .accordion-content .accordion-info {
  width: 60%;
  white-space: nowrap;
  font-size: 0;
}
.announcements-container .accordion-container .accordion-tab .accordion-content .accordion-info:before {
  content: "";
  display: inline-block;
  height: 44px;
  vertical-align: middle;
}
.announcements-container .accordion-container .accordion-tab .accordion-content .accordion-stat {
  width: 40%;
  text-align: left;
}
.announcements-container .accordion-container .accordion-tab .accordion-content .date,
.announcements-container .accordion-container .accordion-tab .accordion-content .accordion-title {
  font-size: 14px;
  line-height: 22px;
}
.announcements-container .accordion-container .accordion-tab .accordion-content > div {
  display: inline-block;
  vertical-align: middle;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content {
  background-color: #f9f9f9;
  font-size: 0;
  text-align: justify;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container {
  padding: 24px 30px 30px;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper {
  display: inline-block;
  width: 33.3%;
  max-width: 390px;
  padding: 0 5px;
  box-sizing: border-box;
  vertical-align: top;
  cursor: default;
}
@media screen and (max-width: 750px) {
  .announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper:not(:first-child) {
    margin-top: 10px;
  }
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper .card {
  width: 100%;
  padding: 16px 24px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ececec;
  text-align: left;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper .card .card-header {
  position: relative;
  width: 100%;
  margin: 0 0 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: #242a32;
  text-transform: capitalize;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper .card .card-header > span:nth-child(2) {
  position: absolute;
  right: 0;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .card-container .card-wrapper .card .card-header + div > span {
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  color: #363636;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .text-wrapper {
  padding: 30px;
  font-size: 1rem;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .link-wrapper {
  width: 100%;
  height: 100%;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .embed-container {
  position: relative;
  padding-bottom: 50.25%;
  height: 0;
  overflow: hidden;
  max-width: 90%;
  margin: auto;
  margin-bottom: 30px;
}
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .embed-container iframe,
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .embed-container object,
.announcements-container .accordion-container .accordion-tab .accordion-tab-content .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.announcements-container .accordion-container .accordion-tab .accordion-content + .accordion-tab-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.admin {
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template-rows: 8% 1fr;
}
@media (min-width: 1024px) {
  .admin {
    grid-template-rows: unset;
    grid-template-columns: 8.5% 1fr;
  }
}
.admin .sidebar {
  background-color: #1a1a2e;
  color: white;
}
@media (min-width: 1024px) {
  .admin .sidebar {
    width: 100%;
  }
}
.admin .sidebar .sidebar-body {
  width: 100%;
}
@media (min-width: 1024px) {
  .admin .sidebar .sidebar-body {
    flex-direction: column;
  }
}
.admin .sidebar .sidebar-body .sidebar-link {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 2px;
  margin-right: 5px;
  border-radius: 5px;
  border-width: 0 2px 2px 2px;
  border-style: solid;
  border-color: whitesmoke;
}
@media (min-width: 1024px) {
  .admin .sidebar .sidebar-body .sidebar-link {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.admin .sidebar .sidebar-body .sidebar-link:hover {
  background-color: grey;
}
.admin .sidebar .sidebar-body .selected {
  background-color: grey;
  border-radius: 4px;
}
@media only screen and (max-width: 1023px) {
  .admin .sidebar {
    padding-top: 10px;
    background-color: #1a1a2e;
    color: white;
    white-space: nowrap;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 1024px) {
  .admin .sidebar {
    width: 120px;
  }
}
@media only screen and (max-width: 1023px) {
  .admin .sidebar .sidebar-body {
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
.admin .admin-wrapper {
  width: 100%;
  overflow-x: auto;
}
@media (min-width: 1024px) {
  .admin .admin-wrapper {
    margin-left: 0.5rem;
  }
}
.admin .admin-wrapper .admin-table {
  margin: 5px;
}
.admin .admin-wrapper .table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}
.admin .admin-wrapper .table button {
  background-color: #1a1a2e;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.admin .admin-wrapper .table button:hover {
  background-color: #0056b3;
}
.admin .admin-wrapper .table .table-head {
  background-color: #b3b2b2;
  text-align: left;
}
.admin .admin-wrapper .table th {
  padding: 5px;
}
.admin .admin-wrapper .table td {
  padding: 5px;
}
.admin .admin-wrapper .table tbody tr:nth-child(even) {
  background-color: #f3f2f2;
}
.admin .admin-wrapper .table tbody tr:hover {
  background-color: #cccccc;
}

.user_admin {
  margin: 25px;
  width: 95%;
  background-color: white;
  border: 1px solid #bac4ce;
  border-radius: 3px;
}
.user_admin .user_admin-header {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #bac4ce;
}
.user_admin .user_admin-header .user_admin-headerLeft {
  width: 40%;
}
.user_admin .user_admin-header .user_admin-headerRight {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.user_admin .user_admin-header .user_admin-headerTitle {
  font-size: 24px;
  font-weight: 600;
}
.user_admin .user_admin-header .user_admin-headerSearch,
.user_admin .user_admin-header .user_admin-headerOrder,
.user_admin .user_admin-header .user_admin-headerActions {
  border: 1px solid #bac4ce;
  border-radius: 3px;
  width: 30%;
}
.user_admin .user_admin-header .user_admin-headerActions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_admin .user_admin-header .user_admin-headerSearch:focus {
  outline: none;
}
.user_admin .user_admin-body .user_admin-table .user_admin-tableHeader {
  display: grid;
  grid-template-columns: 100px 150px 150px 1fr 100px 100px 100px;
}
.user_admin .user_admin-body .user_admin-table .user_admin-tableHeader p {
  background-color: #bac4ce;
  height: 25px;
  text-align: center;
}
.user_admin .user_admin-body .user_admin-table .user_admin-tableBody .row {
  display: grid;
  grid-template-columns: 100px 150px 150px 1fr 100px 100px 100px;
}
.user_admin .user_admin-body .user_admin-table .user_admin-tableBody .row p {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.user_admin .user_admin-body .user_admin-table .user_admin-tableBody .row:nth-child(even) {
  background-color: #e4dfdd;
}

.filterDropdown {
  font-size: 18px;
  margin: 10px;
  border-radius: 8px;
  background-color: #6f8295;
  color: white;
}

.filter-label {
  font-size: 18px;
}

@media (max-width: 800px) {
  .filterDropdown {
    font-size: 13px;
    padding: 1px;
    width: 50%;
  }
  .filters-menu {
    display: flex;
    flex-direction: column;
  }
}
.dashboard {
  padding: 0 20px 0 20px;
  min-height: 89vh;
  display: grid;
  grid-template-rows: 10% 1fr;
}
.dashboard .header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.dashboard .header .week-day {
  width: 45px;
  height: 60px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: #323232;
  color: #f9f6f7;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 1fr 2fr;
  transition: 0.3s ease;
}
@media (min-width: 1024px) {
  .dashboard .header .week-day {
    width: 65px;
    height: 75px;
  }
}
@media (max-width: 365px) {
  .dashboard .header .week-day {
    width: 38px;
    height: 50px;
  }
}
.dashboard .header .week-day:hover {
  opacity: 70%;
}
.dashboard .header .week-day .day {
  font-size: 15px;
  text-align: center;
}
@media (min-width: 1024px) {
  .dashboard .header .week-day .day {
    font-size: 20px;
  }
}
.dashboard .header .week-day .date {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 1024px) {
  .dashboard .header .week-day .date {
    font-size: 30px;
  }
}
.dashboard .header .checkedIn {
  color: #323232;
  background-color: #f39010;
}
.dashboard .header .today {
  color: #f39010;
}
.dashboard .header .checkedIn.today {
  color: white;
}
.dashboard .header .locked {
  filter: invert(60%);
}
.dashboard .header .locked:hover {
  opacity: unset;
}
.dashboard .body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard .body .successful-signup-message {
  padding: 0 300px 20px 300px;
  font-size: 18px;
}
.dashboard .body .successful-signup-message .download-app-link {
  color: #675dff;
}

.checkin-form {
  display: unset;
}
.checkin-form .modal-header {
  padding: 15px;
  width: 100%;
  height: 10%;
  border-bottom: 1px solid black;
}
@media (min-width: 1024px) {
  .checkin-form .modal-header {
    height: 15%;
  }
}
.checkin-form .modal-header .modal-title {
  font-size: 25px;
  text-align: justify;
}
.checkin-form .modal-header .modal-close {
  margin-right: 5px;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  font-size: 25px;
  padding: 0px 5px;
}
.checkin-form .modal-header .modal-close:hover {
  opacity: 60%;
}
.checkin-form .modal-body {
  width: 100%;
  height: 75%;
  /* spacing as needed */
  padding: 10px;
  /* let it scroll */
  overflow: auto;
}
@media (min-width: 1024px) {
  .checkin-form .modal-body {
    height: 70%;
  }
}
.checkin-form .modal-footer {
  padding: 5px 5px 5px 10px;
  width: 100%;
  height: 15%;
  border-top: 1px solid black;
}

.checkin-body {
  display: grid;
  grid-template-rows: 1fr 8fr;
}
.checkin-body .workout-details {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
@media (min-width: 1024px) {
  .checkin-body .workout-details {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
}
.checkin-body .workout-details .time-container .time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}
.checkin-body .workout-details .time-container .time .hours,
.checkin-body .workout-details .time-container .time .minutes {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.checkin-body .picture-container {
  height: 200px;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
  border-top: 1px solid #3b3b3b;
}
.checkin-body .picture-container input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.checkin-body .picture-container label {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 3px;
}
.checkin-body .picture-container .img-preview {
  display: flex;
  justify-content: center;
}
.checkin-body .picture-container .img-preview img {
  width: 100%;
  height: auto;
  align-self: flex-start;
}
@media (min-width: 1024px) {
  .checkin-body .picture-container .img-preview img {
    max-width: 400px;
  }
}
.checkin-body .time-container,
.checkin-body .workout-container,
.checkin-body .picture-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  .checkin-body .time-container,
  .checkin-body .workout-container,
  .checkin-body .picture-container {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
  }
}
.checkin-body .time-container label,
.checkin-body .workout-container label,
.checkin-body .picture-container label {
  margin: auto;
  font-size: 20px;
  margin-bottom: 8px;
}
.checkin-body .time-container select,
.checkin-body .workout-container select,
.checkin-body .picture-container select {
  border-radius: 5px;
  border: 1px solid gray;
}
.checkin-body .amount {
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
}

.modal-footer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-footer button {
  grid-column: 4;
  height: 60%;
  width: 30%;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #f39010;
  color: black;
  font-weight: bold;
  margin: auto;
}
.modal-footer button:hover {
  opacity: 50%;
}
.modal-footer button:disabled {
  opacity: 0.5;
}

.message-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-container .registration-message {
  padding: 15px;
  max-width: 600px;
  font-size: 20px;
}

@media (max-width: 800px) {
  .dashboard {
    padding: 0;
  }
  .dashboard .body .successful-signup-message {
    padding: 0 10px 20px 10px;
  }
}
.Modal {
  position: fixed;
  height: 650px;
  max-height: 85%;
  width: 700px;
  max-width: 90%;
  border-radius: 4px;
  padding: 5px;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #595959;
  outline: none;
  z-index: 2;
  @import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
  font-family: "Inconsolata", serif;
}
@media (min-width: 1024px) {
  .Modal {
    height: 600px;
    max-height: 100%;
    width: 700px;
    max-width: 100%;
  }
}
.Modal .modal-header {
  padding: 15px;
  width: 100%;
  height: 10%;
  border-bottom: 1px solid black;
}
@media (min-width: 1024px) {
  .Modal .modal-header {
    height: 15%;
  }
}
.Modal .modal-header .modal-title {
  font-size: 25px;
}
.Modal .modal-header .modal-close {
  margin-right: 5px;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  font-size: 25px;
  padding: 0px 5px;
}
.Modal .modal-header .modal-close:hover {
  opacity: 60%;
}

.Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2;
}

.challengesToDo,
.challengesDone {
  width: 100%;
  max-width: 400px;
  border-top: 1px solid black;
  padding: 5px;
}
@media (min-width: 1024px) {
  .challengesToDo,
  .challengesDone {
    max-width: 600px;
  }
}
.challengesToDo .header,
.challengesDone .header {
  font-size: 22px;
  margin: 10px 0;
}
.challengesToDo .challenge,
.challengesDone .challenge {
  margin-bottom: 5px;
  width: 100%;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #323232;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s ease;
}
.challengesToDo .challenge:hover,
.challengesDone .challenge:hover {
  opacity: 70%;
}
.challengesToDo .challenge .challenge-title,
.challengesToDo .challenge .challenge-value,
.challengesDone .challenge .challenge-title,
.challengesDone .challenge .challenge-value {
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  color: #f9f6f7;
}
.challengesToDo .submission,
.challengesDone .submission {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.challengesToDo .submission:hover,
.challengesDone .submission:hover {
  opacity: unset;
}

.challenge-form {
  height: 100%;
}

.challenges-modal-body {
  height: 70%;
  padding: 20px;
  overflow: auto;
}
.challenges-modal-body .challenge-summary {
  font-size: 20px;
  text-align: justify;
}
.challenges-modal-body .switch-input {
  margin: auto;
  margin-top: 30px;
  width: fit-content;
}
.challenges-modal-body .switch-input .time-inputs,
.challenges-modal-body .switch-input .amount-input {
  width: 300px;
}
.challenges-modal-body .switch-input .time-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.challenges-modal-body .switch-input .time-inputs .time-input {
  display: grid;
  grid-template-rows: 80% 20%;
}
.challenges-modal-body .switch-input .time-inputs .time-input input {
  width: 50px;
  height: 50px;
  font-size: 20px;
  text-align: center;
}
@media (min-width: 1024px) {
  .challenges-modal-body .switch-input .time-inputs .time-input input {
    width: 70px;
    height: 70px;
  }
}
.challenges-modal-body .switch-input .time-inputs .time-input label {
  padding-top: 5px;
  font-size: 12px;
  text-align: center;
}
.challenges-modal-body .switch-input .amount-input {
  height: 75px;
  display: grid;
  justify-content: center;
}
.challenges-modal-body .switch-input .amount-input input {
  width: 100%;
  height: 40px;
  padding: 1em;
  margin: auto;
}
@media (min-width: 1024px) {
  .challenges-modal-body .switch-input .amount-input input {
    font-size: 20px;
  }
}

.challenges-modal-footer {
  height: 10%;
}
.challenges-modal-footer button {
  height: 100%;
  width: 50%;
}

.challengeAdmin {
  height: 100%;
}
.challengeAdmin .challenge-header {
  margin: 10px;
  height: 5%;
  display: flex;
  justify-content: space-between;
}
.challengeAdmin .challenge-header button {
  height: 30px;
  width: 100px;
}
.challengeAdmin .challenge-body {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.challengeAdmin .challenge-body .challenge-item {
  width: 100%;
  max-width: 400px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 15px;
  display: grid;
  grid-template-rows: 1fr 25%;
}
.challengeAdmin .challenge-body .challenge-item .item-details {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.challengeAdmin .challenge-body .item-buttons button {
  width: 50px;
  height: 20px;
  margin-left: 10px;
}

.edit-body {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.edit-body .input-container {
  display: grid;
  grid-template-rows: 20px 1fr;
}
.edit-body .input-container .edit-input {
  height: 35px;
  margin-bottom: 15px;
}
.edit-body .input-container .switch-buttons button {
  height: 25px;
  width: 100px;
}
.edit-body .input-container .switch-buttons .Active {
  background-color: greenyellow;
}

.edit-modal-footer {
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.edit-modal-footer button {
  width: 150px;
  height: 50px;
}

.modal-delete {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal-delete .delete-text {
  height: 200px;
  display: flex;
  flex-direction: column;
}
.modal-delete .delete-text label {
  height: 25px;
}
.modal-delete .delete-text input {
  height: 50px;
}
.modal-delete button {
  height: 50px;
}

.modal-create {
  height: 400px;
  display: flex;
  flex-direction: column;
}
.modal-create .input-container {
  display: grid;
  grid-template-columns: 20% 1fr;
  height: 35px;
  margin: 5px 0 5px 0;
}
.modal-create .input-container.summary {
  height: 140px;
}

.modal-create-footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.modal-create-footer button {
  height: 50px;
  width: 200px;
}

.profile-container {
  display: flex;
  justify-content: center;
}
.profile-container .registration {
  width: 100%;
  height: auto;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-container .registration .pr-form {
  max-width: 500px;
  width: 100%;
  display: grid;
  grid-template-rows: 50px 1fr;
}
.profile-container .registration .pr-form .pr-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.profile-container .registration .pr-form .pr-body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.profile-container .profile {
  width: 80%;
  display: flex;
  justify-content: center;
  padding: 15px;
}
.profile-container .profile .profile-header {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.profile-container .profile .input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.profile-container .profile .form-input {
  width: 100%;
  margin-bottom: 15px;
}
.profile-container .profile .form-input .profile-input {
  width: 90%;
  height: 35px;
}
.profile-container .button-container {
  width: 100%;
  display: flex;
}
.profile-container .button-container .form-button {
  margin: 10px;
  width: calc(100% - 30px);
  height: 40px;
  background-color: #292929;
  color: white;
  border: transparent;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.Payment-Form {
  min-width: 350px;
  min-height: 430px;
  padding: 5px;
  border: 0.5px solid #292929;
  border-radius: 5px;
  position: relative;
}
.Payment-Form .payment-header {
  text-align: center;
  font-size: 20px;
}
.Payment-Form .payment-body {
  position: absolute;
  transition: opacity 1s;
}
.Payment-Form .payment-body .billing .billing-header {
  margin-left: 5px;
  font-size: 20px;
  color: #292929;
}
.Payment-Form .payment-body .billing .billing-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.Payment-Form .payment-body .billing .billing-body .item-1 {
  grid-column: 1/3;
}
.Payment-Form .payment-body .payment-info {
  margin: 15px;
}
.Payment-Form .payment-body .err {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: large;
}
.Payment-Form .payment-body .payment-button-container {
  display: flex;
  justify-content: center;
}
.Payment-Form .payment-body .payment-button-container .payment-button {
  margin: 10px;
  width: calc(100% - 30px);
  height: 40px;
  background-color: #292929;
  color: white;
  border: transparent;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.Payment-Form .payment-body.hide {
  opacity: 0;
  pointer-events: none;
}
.Payment-Form .proccessing-container {
  width: 100%;
  min-height: 280px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  transition-delay: 0.5s;
  opacity: 1;
}
.Payment-Form .proccessing-container.hide {
  opacity: 0;
  pointer-events: none;
}

.payment-other-form {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.payment-other-form .upload-box {
  margin: 15px;
  height: 150px;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.payment-other-form .upload-box p {
  width: 100%;
  font-size: 18px;
  text-align: center;
}
.payment-other-form .upload-box .payment-upload-icon {
  font-size: 50px;
}
.payment-other-form .upload-box hr {
  width: 100%;
  border: none;
}
.payment-other-form .upload-box .upload-box-button {
  margin: 10px;
  width: calc(100% - 30px);
  height: 35px;
  background-color: #292929;
  color: white;
  border: transparent;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.payment-other-form .upload-box:hover {
  opacity: 0.7;
}

.submission-pending {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submission-success {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.submission-success .submission-icon {
  font-size: 60px;
}
.submission-success .submission-text {
  font-size: 20px;
}
.submission-success .submission-icon,
.submission-success .submission-text {
  display: flex;
  justify-self: center;
  align-self: center;
  text-align: center;
  color: green;
}

.float-label {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  position: relative;
  margin: 5px;
}
.float-label input {
  width: 100%;
  height: 50px;
  padding: 14px 16px 0 10px;
  outline: 0;
  border: none;
  border-bottom: 1px solid #ddd;
  background: #fff;
  font-size: 16px;
}
.float-label label {
  font-size: 16px;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.3s ease-out;
}
.float-label .Active {
  transform: translate(0, 5px) scale(0.75);
  color: #ec0101;
}
.float-label ::placeholder {
  color: transparent;
  transition: all 0.3s ease-out;
}

.float-label:focus-within ::placeholder {
  color: grey;
}
.float-label:focus-within label {
  transform: translate(0, 5px) scale(0.75);
  color: #ec0101;
}

.slide-option {
  width: 100%;
  max-height: 500px;
  margin-bottom: 5px;
  transition: 0.7s ease-in-out;
}
.slide-option .slide-option-header {
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  margin-bottom: 5px;
  cursor: pointer;
}
.slide-option .slide-option-header .slide-header-icon {
  display: flex;
  justify-self: center;
  align-self: center;
  font-size: 25px;
}
.slide-option .slide-option-header .slide-header-title {
  display: flex;
  justify-self: center;
  align-self: center;
  font-size: 20px;
}
.slide-option .slide-option-body {
  border-top: none;
  max-height: 100%;
  transition: 0.7s ease-in-out;
  overflow: auto;
}
.slide-option .slide-option-body.hide {
  max-height: 0%;
  transition-delay: display 0.7s;
  overflow: hidden;
  transition: 1s ease-in-out;
}
.slide-option.close {
  max-height: 40px;
  transition: 1s ease-in-out;
}

.score-table {
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
  padding-bottom: 20px;
}

.score-table-head th {
  text-align: start;
  background-color: #b3b2b2;
  padding: 5px;
}

.tbody tr:nth-child(even) {
  background-color: #f3f2f2;
}

.tbody tr:hover {
  background-color: #cccccc;
}

.tbody td {
  padding: 5px;
}

@media (max-width: 470px) {
  .score-table {
    margin: auto;
    width: 80%;
  }
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page-wrapper .wrapper-store {
  flex: 1;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.page-wrapper .wrapper-store .horizontal-line {
  border: 1px solid #a3aed0;
  margin: 0 4% 0 4%;
}
.page-wrapper .wrapper-store .store-top {
  background-color: 0;
}
.page-wrapper .wrapper-store .store-top .store-header {
  margin: 0 3% 0 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-wrapper .wrapper-store .store-top .store-header .store-img-kaos {
  display: flex;
  justify-content: start;
  align-items: center;
}
.page-wrapper .wrapper-store .store-top .store-header .store-img-kaos .kaos-title {
  display: flex;
  justify-content: start;
  font-size: 3em;
  padding-left: 5px;
  border-radius: 5px;
  max-width: 75%;
  color: black;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-top .store-header .store-img-kaos .kaos-title {
    font-size: 2em;
    max-width: 100%;
  }
}
.page-wrapper .wrapper-store .store-top .store-header .store-img-kaos img {
  max-width: 6%;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-top .store-header .store-img-kaos img {
    max-width: 10%;
  }
}
.page-wrapper .wrapper-store .store-title {
  margin: 0 3% 0 3%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2em;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-title {
    font-size: 1.5em;
  }
}
.page-wrapper .wrapper-store .store-title .store-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-wrapper .wrapper-store .store-title .store-icon .store-icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #bd2029;
}
.page-wrapper .wrapper-store .store-title .store-icon .store-icon-link:hover {
  color: #bd2029;
}
.page-wrapper .wrapper-store .store-title .store-icon a {
  text-decoration: none;
  color: #bd2029;
}
.page-wrapper .wrapper-store .store-title .store-icon a:hover {
  color: #bd2029;
}
.page-wrapper .wrapper-store .store-body .store-decoration-products {
  margin: 0 3% 0 3%;
  padding-top: 12px;
}
.page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  border: none;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info {
    justify-content: start;
  }
}
.page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info .store-decoration-products-info-title h4 {
  font-size: 2em;
  gap: 2px;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info .store-decoration-products-info-title h4 {
    font-size: 1.4em;
  }
}
.page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info .store-decoration-products-info-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  color: #a3aed0;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info .store-decoration-products-info-text {
    display: block;
    justify-content: start;
  }
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-body .store-decoration-products .store-decoration-products-info .store-decoration-products-info-text p {
    font-size: 0.8em;
  }
}
.page-wrapper .wrapper-store .store-body .products-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  padding-right: 5%;
  border-radius: 5px;
  color: black;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-body .products-title {
    font-size: 1em;
  }
}
.page-wrapper .wrapper-store .store-body .product-card-content {
  margin: 0 3% 0 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .page-wrapper .wrapper-store .store-body .product-card-content {
    flex-direction: column;
  }
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper {
  max-width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 1px;
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper .product-card a {
  text-decoration: none;
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper .product-card .product-card-content-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper .product-card .product-card-content-img .product-img-wrapper {
  border-radius: 1px;
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper .product-card .product-card-container .product-facture-text-card {
  background: white;
  border-radius: 1px;
  padding: 5px;
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper .product-card .product-card-container .product-facture-text-card .product-name {
  color: black;
}
.page-wrapper .wrapper-store .store-body .product-card-content .product-card-wrapper .product-card .product-card-container .product-facture-text-card .product-price {
  padding-top: 5px;
  font-weight: bold;
  color: #a3aed0;
}
.page-wrapper .store-footer-wrapper {
  background: #bd2029;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  bottom: 0;
}
@media (max-width: 600px) {
  .page-wrapper .store-footer-wrapper {
    display: block;
    padding: 0px 10px 0px 10px;
    padding-top: 15px;
  }
}
.page-wrapper .store-footer-wrapper .store-footer-Our-Mission {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.page-wrapper .store-footer-wrapper .store-footer-Our-Mission .Our-Mission h2 {
  color: white;
  text-decoration-color: white;
  padding: 1px;
  font-size: 2em;
}
@media (max-width: 600px) {
  .page-wrapper .store-footer-wrapper .store-footer-Our-Mission .Our-Mission h2 {
    font-size: 1.5em;
  }
}
.page-wrapper .store-footer-wrapper .store-footer-Our-Mission .Our-Mission-text p {
  color: white;
  padding: 1px;
  font-size: 1.2em;
}
@media (max-width: 600px) {
  .page-wrapper .store-footer-wrapper .store-footer-Our-Mission .Our-Mission-text p {
    padding: 2px;
    font-size: 0.9em;
  }
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .Quick-links-title h3 {
  color: white;
  padding: 5px;
  font-size: 1.5em;
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .Quick-links-content {
  display: flex;
  text-align: center;
  gap: 2px;
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .Quick-links-content .Quick-links {
  display: flex;
  justify-content: center;
  text-align: center;
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .Quick-links-content .Quick-links a {
  color: rgb(247, 245, 245);
  text-decoration: none;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 1.2em;
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .Quick-links-content .Quick-links a:hover {
  text-decoration: underline;
}
@media (max-width: 600px) {
  .page-wrapper .store-footer-wrapper .store-footer-Quick-links .Quick-links-content .Quick-links a {
    padding-left: 1px;
    padding-right: 1px;
    font-size: 0.9em;
  }
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .socialmedia-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 600px) {
  .page-wrapper .store-footer-wrapper .store-footer-Quick-links .socialmedia-wrapper {
    justify-content: center;
    padding: 5px;
  }
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .socialmedia-wrapper .socilamedia-icon a {
  color: white;
  font-size: 2em;
}
.page-wrapper .store-footer-wrapper .store-footer-Quick-links .socialmedia-wrapper .socilamedia-icon a:hover {
  color: #292929;
}
@media (max-width: 600px) {
  .page-wrapper .store-footer-wrapper .store-footer-Quick-links .socialmedia-wrapper .socilamedia-icon a {
    font-size: 2em;
  }
}

.mobileLandingContainer {
  width: 100%;
  text-align: center;
}
.mobileLandingContainer h1 {
  margin-bottom: 10px;
}
.mobileLandingContainer > .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.mobileLandingContainer .column {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: center;
}
.mobileLandingContainer .column #app-store-badge {
  margin: 0 auto;
  overflow: hidden;
  border-radius: 13px;
  width: 250px;
  height: 83px;
}
.mobileLandingContainer .column #app-store-badge img {
  border-radius: 13px;
  width: 250px;
  height: 83px;
}
.mobileLandingContainer .column #play-store-badge img {
  width: 288px;
}
.mobileLandingContainer .column #kos-app-screenshot {
  min-width: 200px;
  max-width: 300px;
}