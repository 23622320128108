//styles Feed
@import "../scroll/scrolll.scss";

.content-feed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    scroll-behavior: auto;
}

.peopleSpinner {
  width: fit-content;
  margin: auto;
}

.row {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .column {
        padding-left: 1rem;
        padding-right: 1rem;
        .single-property {
            width: 300px;
            border-radius: 5px;
            margin-bottom: 15px;
            overflow: hidden;
            .card {
                background: #30343a;
                max-width: 400px;
                color: white;
                font-family: poppins;
                .property-thumb {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #30343a;
                    .property-date {
                        padding: 5px;
                        font-size: 1em;
                        font-weight: 500;
                    }

                    .property-username {
                        display: flex;
                        padding: 5px;
                        font-size: 1em;
                        font-weight: 900;
                    }
                } //property-thumb
                .horizontal-line {
                    border: 1px solid #a3aed0;
                }
                .property-content {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    .property-workoutType {
                        font-size: 1.2em;
                        font-weight: 500;
                    }

                    .property-workoutLength {
                        font-weight: 200;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        .icon-timer {
                            font-size: 2em;
                        }
                       
                    }
                } //property-content

                .property-footer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    overflow: hidden;
                    background: #30343a;
                    img {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        border-radius: 5px;
                        max-width: 250px;
                        vertical-align: middle;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}
