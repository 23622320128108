.content-scroll .btn-scroll-up {
  position: fixed;
  width: 100%;
  left: 46%;
  bottom: 50px;
  margin-bottom: 10px;
  font-size: 2.1rem;
  z-index: 1;
  cursor: pointer;
  color: grey;
}
.content-scroll .btn-scroll-up:hover {
  color: rgb(81, 78, 78);
}
@media (max-width: 600px) {
  .content-scroll .btn-scroll-up {
    left: 45%;
    color: rgba(128, 128, 128, 0.532);
  }
}
.content-scroll .btn-scroll-down {
  position: fixed;
  width: 100%;
  left: 46%;
  bottom: 25px;
  margin-top: 10px;
  font-size: 2rem;
  z-index: 1;
  cursor: pointer;
  color: green;
}
@media (max-width: 600px) {
  .content-scroll .btn-scroll-down {
    left: 45%;
  }
}

.content-feed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scroll-behavior: auto;
}

.peopleSpinner {
  width: fit-content;
  margin: auto;
}

.row {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.row .column {
  padding-left: 1rem;
  padding-right: 1rem;
}
.row .column .single-property {
  width: 300px;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;
}
.row .column .single-property .card {
  background: #30343a;
  max-width: 400px;
  color: white;
  font-family: poppins;
}
.row .column .single-property .card .property-thumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #30343a;
}
.row .column .single-property .card .property-thumb .property-date {
  padding: 5px;
  font-size: 1em;
  font-weight: 500;
}
.row .column .single-property .card .property-thumb .property-username {
  display: flex;
  padding: 5px;
  font-size: 1em;
  font-weight: 900;
}
.row .column .single-property .card .horizontal-line {
  border: 1px solid #a3aed0;
}
.row .column .single-property .card .property-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.row .column .single-property .card .property-content .property-workoutType {
  font-size: 1.2em;
  font-weight: 500;
}
.row .column .single-property .card .property-content .property-workoutLength {
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row .column .single-property .card .property-content .property-workoutLength .icon-timer {
  font-size: 2em;
}
.row .column .single-property .card .property-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: #30343a;
}
.row .column .single-property .card .property-footer img {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  max-width: 250px;
  vertical-align: middle;
  transition: 0.3s;
}