.contentCarousel {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contentImage {
    display: flex;
    justify-content: center;
    text-align: center;
    img {
      max-width: 220px;
      height: auto;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .contentButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-top: 10px;

    .statusSection {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 15px;
      font-size: 18px;
      font-weight: bold;
      color: #333;

      div {
        padding: 5px 10px;
        background-color: #f0f0f0;
        border-radius: 5px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      }

      .current {
        color: #007bff;

        .total {
          color: #ff5733;
        }
      }
    }

    button {
      background-color: #1a1a2e;
      color: white;
      border: none;
      padding: 0px 20px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 1.2em;
      transition: background-color 0.3s ease;
    }

    .back {
      margin-right: 10px;
      &:hover {
        background-color: #3c3c44;
      }

      &:disabled {
        background-color: rgba(23, 37, 51, 0.103);
        cursor: not-allowed;
      }
    }

    .next {
      margin-left: 10px;
      &:hover {
        background-color: #3c3c44;
      }

      &:disabled {
        background-color: rgba(23, 37, 51, 0.103);
        cursor: not-allowed;
      }
    }
  }
}
