$charcoal: #323232;
$off-white: #f9f6f7;
$almost-gold: #f39010;
$off-gray: #3b3b3b;

$Black: #0c0b10;
$Grey: #151518;
$LightGrey: #6d6d6d;
$Tan: #a67960;
$Red: #b00606;
$White: #e4dfdd;

