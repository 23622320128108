.float-label {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  position: relative;
  margin: 5px;

  input {
    width: 100%;
    height: 50px;
    padding: 14px 16px 0 10px;
    outline: 0;
    border: none;
    border-bottom: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
  }

  label {
    font-size: 16px;
    padding: 0 12px;
    color: #999;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.3s ease-out;
  }

  .Active {
    transform: translate(0, 5px) scale(0.75);
    color: #ec0101;
  }

  ::placeholder {
    color: transparent;
    transition: all 0.3s ease-out;
  }
}

.float-label:focus-within {
  ::placeholder{
    color: grey;
  }

  label {
    transform: translate(0, 5px) scale(0.75);
    color: #ec0101;
  }
}
