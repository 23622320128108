.goalsAdmin {
  .tableGoals {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border-radius: 10px;
    // overflow: hidden;
    table-layout: auto;
    .table-head {
      background-color: #b3b2b2;
      color: #fff;
      text-align: left;
    }

    button {
      background-color: #1a1a2e;
      color: white;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: 4px;
    }

    button:hover {
      background-color: #0056b3;
    }

    .goalsUser {
      background: #e4e2e2;
    }

    th {
      text-align: start;
      padding: 5px 10px;
      margin: 10px;
      white-space: nowrap;
    }

    .thAthlete {
      width: 209px;
    }

    td {
      padding: 5px;
      white-space: nowrap;
    }

    tbody tr:nth-child(even) {
      background-color: #e1eaf4;
    }

    tbody tr:hover {
      background-color: #f6e3cf;
    }
  }
}

.goalsUser {
  td {
    padding: 0;
  }

  .nested-table-container {
    overflow: auto;
    max-height: 200px;
    width: 100%;
  }

  .nested-table {
    width: 100%;
    border-collapse: collapse;

    textarea {
      width: 100%;
      height: 60px;

      box-sizing: border-box;
      border: 2px solid #ccc;
      background-color: #f8f8f8;
      resize: none;
    }

    th,
    td {
      padding: 8px;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
    }
  }
}
