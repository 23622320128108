* {
  margin: 0;
  padding: 0;
  font-family: poppins;
}

html {
  // overflow: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
