.modalWrapper {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContainer {
  position: relative;
  border-radius: 6px;
  background-color: #fefefe;
  margin: 5% auto;
  width: 80%;
  z-index: 100;
  padding: 1rem;
  min-height: 200px;
  min-width: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.548), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.closed {
  text-align: right;
  font-size: 1.25rem;
  font-weight: bold;
}
.closed span {
  cursor: pointer;
  font-size: 1.5em;
  padding-right: 0.5rem;
}
.closed span:hover {
  color: #d0d0d0;
}