.content-scroll {
    .btn-scroll-up {
        position: fixed;
        width: 100%;
        left: 46%;
        bottom: 50px;
        margin-bottom: 10px;
        font-size: 2.1rem;
        z-index: 1;
        cursor: pointer;
        color: grey;

        &:hover {
            color: rgb(81, 78, 78);
        }

        @media (max-width: 600px) {
            left: 45%;
            color: rgba(128, 128, 128, 0.532);
        }
    }

    .btn-scroll-down {
        position: fixed;
        width: 100%;
        left: 46%;
        bottom: 25px;
        margin-top: 10px;
        font-size: 2rem;
        z-index: 1;
        cursor: pointer;
        color: green;

        @media (max-width: 600px) {
            left: 45%;
        }
    }
}
