.profile-container {
  display: flex;
  justify-content: center;

  .registration {
    width: 100%;
    height: auto;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .pr-form {
      max-width: 500px;
      width: 100%;
      display: grid;
      grid-template-rows: 50px 1fr;

      .pr-header {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .pr-body {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  .profile {
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 15px;

    .profile-header {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
    .input-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .form-input {
      width: 100%;
      margin-bottom: 15px;

      .profile-input {
        width: 90%;
        height: 35px;
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;

    .form-button {
      margin: 10px;
      width: calc(100% - 30px);
      height: 40px;
      background-color: #292929;
      color: white;
      border: transparent;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.Payment-Form {
  min-width: 350px;
  min-height: 430px;
  padding: 5px;
  border: 0.5px solid #292929;
  border-radius: 5px;
  position: relative;

  .payment-header {
    text-align: center;
    font-size: 20px;
  }

  .payment-body {
    position: absolute;
    transition: opacity 1s;

    .billing {
      .billing-header {
        margin-left: 5px;
        font-size: 20px;
        color: #292929;
      }

      .billing-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        .item-1 {
          grid-column: 1/3;
        }
      }
    }

    .payment-info {
      margin: 15px;
    }

    .err {
      display: flex;
      justify-content: center;
      align-items: center;
      color: red;

      font-size: large;
    }

    .payment-button-container {
      display: flex;
      justify-content: center;

      .payment-button {
        margin: 10px;
        width: calc(100% - 30px);
        height: 40px;
        background-color: #292929;
        color: white;
        border: transparent;
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .proccessing-container {
    // min-width: 335px;
    width: 100%;
    min-height: 280px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    transition-delay: 0.5s;
    opacity: 1;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.payment-other-form {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .upload-box {
    margin: 15px;
    height: 150px;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    p {
      width: 100%;
      font-size: 18px;
      text-align: center;
    }

    .payment-upload-icon {
      font-size: 50px;
    }

    hr {
      width: 100%;
      border: none;
    }

    .upload-box-button {
      margin: 10px;
      width: calc(100% - 30px);
      height: 35px;
      background-color: #292929;
      color: white;
      border: transparent;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.submission-pending {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submission-success {
  display: grid;
  grid-template-rows: 1fr 1fr;

  .submission-icon {
    font-size: 60px;
  }

  .submission-text {
    font-size: 20px;
  }

  .submission-icon,
  .submission-text {
    display: flex;
    justify-self: center;
    align-self: center;
    text-align: center;
    color: green;
  }
}
