.weightLossAdmin .weightAndVideo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.weightLossAdmin .weightAndVideo .weight {
  margin-left: 10px;
}
.weightLossAdmin .modalContent .contentVideo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.weightLossAdmin .modalContent .contentVideo video {
  width: 100%;
  max-width: 640px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #000;
}