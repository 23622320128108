.forgotPassword {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-items: center;

  margin: auto;

  margin-top: 5vh;

  border-radius: 15px;

  padding: 25px 15px;

  width: 50vw;
  min-width: 300px;

  height: 30vh;
  min-height: 300px;

  background-color: whitesmoke;

  h2 {
    text-align: center;

    background-color: white;

    padding: 7.5px;

    border-radius: 15px;
  }

  input {
    margin: 15px;

    width: 65%;
    height: 35px;

    outline: none;
    border: none;
    border-radius: 5px;

    text-align: center;

    border-bottom: 1px solid black;
  }

  button {
    width: 75px;
    height: 30px;
    margin: 10px auto;
    border-radius: 10px;
  }

  a {
    margin: auto;
    font-size: 22px;
  }
}
