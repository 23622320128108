.goalsAdmin .tableGoals {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 10px;
  table-layout: auto;
}
.goalsAdmin .tableGoals .table-head {
  background-color: #b3b2b2;
  color: #fff;
  text-align: left;
}
.goalsAdmin .tableGoals button {
  background-color: #1a1a2e;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.goalsAdmin .tableGoals button:hover {
  background-color: #0056b3;
}
.goalsAdmin .tableGoals .goalsUser {
  background: #e4e2e2;
}
.goalsAdmin .tableGoals th {
  text-align: start;
  padding: 5px 10px;
  margin: 10px;
  white-space: nowrap;
}
.goalsAdmin .tableGoals .thAthlete {
  width: 209px;
}
.goalsAdmin .tableGoals td {
  padding: 5px;
  white-space: nowrap;
}
.goalsAdmin .tableGoals tbody tr:nth-child(even) {
  background-color: #e1eaf4;
}
.goalsAdmin .tableGoals tbody tr:hover {
  background-color: #f6e3cf;
}

.goalsUser td {
  padding: 0;
}
.goalsUser .nested-table-container {
  overflow: auto;
  max-height: 200px;
  width: 100%;
}
.goalsUser .nested-table {
  width: 100%;
  border-collapse: collapse;
}
.goalsUser .nested-table textarea {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
  resize: none;
}
.goalsUser .nested-table th,
.goalsUser .nested-table td {
  padding: 8px;
  border: 1px solid #ddd;
}
.goalsUser .nested-table th {
  background-color: #f4f4f4;
}