$positive-circle: #82d428;
$negative-circle: #ff6e00;
$circle-gray: #e4e4e4;
$header-black: #242a32;
$background-color: #6fcae7;
$tab-text: #5d5d5d;
$white: #fff;
$reviews-text-black: #363636;
$white-light-gray: #f9f9f9;

// new
.announcements-container {
  padding: 5px;

  .accordion-container {
    .accordion-tab {
      position: relative;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto 10px;
      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 0 0 1px rgba(236, 236, 236, 1);
      opacity: 1;
      transition: box-shadow 0.2s, opacity 0.4s;

      &:hover {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.11);
      }

      .accordion-input {
        display: none;
      }
      .accordion-input:checked ~ .accordion-content + .accordion-tab-content {
        max-height: 3000px;
      }
      .accordion-input:checked ~ .accordion-content:after {
        transform: rotate(0);
      }

      .accordion-label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        max-height: 70px;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 0;
        z-index: 1;
        cursor: pointer;
        &:hover ~ .accordion-content:after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiM1NTZBRUEiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=");
        }

        @media screen and (max-width: 800px) {
          max-height: 80px;
        }

        @media screen and (max-width: 500px) {
          max-height: 90px;
        }
      }

      .accordion-content {
        position: relative;
        display: block;
        height: 80px;
        margin: 0;
        padding: 0 87px 0 30px;
        font-size: 0;
        white-space: nowrap;
        cursor: pointer;
        &:before,
        &:after {
          content: "";
          display: inline-block;
          vertical-align: middle;
        }
        &:before {
          height: 100%;
        }
        &:after {
          position: absolute;
          width: 24px;
          height: 100%;
          right: 30px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNBOUFDQUYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=");
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(180deg);
        }

        @media screen and (max-width: 800px) {
          height: auto;
          padding: 10px 87px 10px 30px;
          &:before {
            display: none;
          }
          &:after {
            top: 0;
          }
        }

        //
        // accordion-content Children

        .accordion-title {
          display: inline-block;
          width: 75%;
          margin-left: 16px;
          font-weight: 500;
          color: $header-black;
          white-space: normal;
          text-align: left;
          vertical-align: middle;
          text-transform: capitalize;
        }

        .accordion-info {
          width: 60%;
          white-space: nowrap;
          font-size: 0;
          &:before {
            content: "";
            display: inline-block;
            height: 44px;
            vertical-align: middle;
          }
        }

        .accordion-stat {
          width: 40%;
          text-align: left;
        }

        .date,
        .accordion-title {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .accordion-content > div {
        display: inline-block;
        vertical-align: middle;

        // @media screen and (max-width: 800px) {
        // display: block;
        // width: 100%;
        // }
      }

      //
      // Hidden stuff / Main content

      .accordion-tab-content {
        background-color: $white-light-gray;
        font-size: 0;
        text-align: justify;

        //
        // accordion-tab-content children

        .card-container {
          padding: 24px 30px 30px;

          .card-wrapper {
            display: inline-block;
            width: 33.3%;
            max-width: 390px;
            padding: 0 5px;
            box-sizing: border-box;
            vertical-align: top;
            cursor: default;

            @media screen and (max-width: 750px) {
              display: block;
              width: 100%;
              max-width: 100%;

              &:not(:first-child) {
                margin-top: 10px;
              }
            }

            //
            // card-wrapper children
            .card {
              width: 100%;
              padding: 16px 24px 20px;
              box-sizing: border-box;
              border-radius: 4px;
              background-color: #fff;
              border: 1px solid #ececec;
              text-align: left;

              //
              // card children
              .card-header {
                position: relative;
                width: 100%;
                margin: 0 0 24px;
                font-size: 15px;
                font-weight: 500;
                line-height: 20px;
                color: $header-black;
                text-transform: capitalize;
              }

              .card-header > span:nth-child(2) {
                position: absolute;
                right: 0;
              }

              .card-header + div > span {
                font-size: 13px;
                font-weight: 400;
                line-height: 22px;
                color: $reviews-text-black;
              }
            }
          }
        }

        .text-wrapper {
          padding: 30px;
          font-size: 1rem;
        }

        .link-wrapper {
          width: 100%;
          height: 100%;
        }

        .embed-container {
          position: relative;
          padding-bottom: 50.25%;
          height: 0;
          overflow: hidden;
          max-width: 90%;
          margin: auto;
          margin-bottom: 30px;
        }

        .embed-container iframe,
        .embed-container object,
        .embed-container embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .accordion-content + .accordion-tab-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s;
      }
    }
  }
}
