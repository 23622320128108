.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .wrapper-store {
        flex: 1;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

            .horizontal-line {
                border: 1px solid #a3aed0;
                margin: 0 4% 0 4%;
            }

            .store-top {
                background-color: green($color: #000000);
                .store-header {
                    margin: 0 3% 0 3%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                        .store-img-kaos {
                            display: flex;
                            justify-content: start;
                            align-items: center;

                                .kaos-title {
                                    display: flex;
                                    justify-content: start;
                                    font-size: 3em;
                                    padding-left: 5px;
                                    border-radius: 5px;
                                    max-width: 75%;
                                    color: black;

                                    @media (max-width: 600px) {
                                        font-size: 2em;
                                        max-width: 100%;
                                    }
                                }
                                img {
                                    max-width: 6%;

                                    @media (max-width: 600px) {
                                        max-width: 10%;
                                    }
                                }
                        } //store-img-kaos
                } //store-header
            }

            .store-title {
                margin: 0 3% 0 3%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 2em;
                @media (max-width: 600px) {
                    font-size: 1.5em;
                }

                .store-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .store-icon-link {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        color: #bd2029;
                        &:hover {
                            color: #bd2029;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: #bd2029;
                        &:hover {
                            color: #bd2029;
                        }
                    }
                }
            } //store-go-to
            .store-body {

                    .store-decoration-products {
                        margin: 0 3% 0 3%;
                        padding-top: 12px;

                        .store-decoration-products-info {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            background: white;
                            border: none;
                            @media (max-width: 600px) {
                                justify-content: start;
                            }
                            .store-decoration-products-info-title {
                                h4 {
                                    font-size: 2em;
                                    gap: 2px;
                                    @media (max-width: 600px) {
                                        font-size: 1.4em;
                                    }
                                }
                            }

                            .store-decoration-products-info-text {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                padding: 5px;
                                color: #a3aed0;
                                @media (max-width: 600px) {
                                    display: block;
                                    justify-content: start;
                                }
                                p {
                                    @media (max-width: 600px) {
                                        font-size: 0.8em;
                                    }
                                }
                            }
                        } //store-decoration-products-info'
                    } //store-decoration-products

                    .products-title {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5em;
                        padding-right: 5%;
                        border-radius: 5px;
                        color: black;

                        @media (max-width: 600px) {
                            font-size: 1em;
                        }
                    }

                    .product-card-content {
                        margin: 0 3% 0 3%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: 600px) {
                            flex-direction: column;
                        }

                        .product-card-wrapper {
                            max-width: 210px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin: 10px;
                            border-radius: 1px;
                            .product-card {
                                a {
                                    text-decoration: none;
                                }
                                .product-card-content-img {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .product-img-wrapper {
                                        border-radius: 1px;
                                        width: 100%;
                                        height: 250px;
                                        object-fit: cover;
                                    }
                                }

                                .product-card-container {
                                    .product-facture-text-card {
                                        background: white;
                                        border-radius: 1px;
                                        padding: 5px;
                                        .product-name {
                                            color: black;
                                        }
                                        .product-price {
                                            padding-top: 5px;
                                            font-weight: bold;
                                            color: #a3aed0;
                                        } //store-price
                                    } //store-facture-text-card
                                } //store-card-container
                            } //store-card
                        } //store-card-wrapper
                    } //store-card-content
            }
    }
    //footer
    .store-footer-wrapper {
        background: #bd2029;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        bottom: 0;

        @media (max-width: 600px) {
            display: block;
            padding: 0px 10px 0px 10px;
            padding-top: 15px;
        }

        .store-footer-Our-Mission {
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;
            
            @media (max-width: 600px) {
            }
            .Our-Mission {
                h2 {
                    color: white;
                    text-decoration-color: white;
                    padding: 1px;
                    font-size: 2em;

                    @media (max-width: 600px) {
                        font-size: 1.5em;
                    }
                }
            }

            .Our-Mission-text {
                p {
                    color: white;
                    padding: 1px;
                    font-size: 1.2em;

                    @media (max-width: 600px) {
                        padding: 2px;
                        font-size: 0.9em;
                    }
                }
            }
        } //store-footer-Our-Mission

        .store-footer-Quick-links {
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;

            .Quick-links-title {
                h3 {
                    color: white;
                    padding: 5px;
                    font-size: 1.5em;
                }
            }
            .Quick-links-content {
                display: flex;
                text-align: center;
                gap: 2px;

                .Quick-links {
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    a {
                        color: rgb(247, 245, 245);
                        text-decoration: none;
                        // padding: 2px;
                        padding-left: 2px;
                        padding-right: 2px;
                        font-size: 1.2em;

                        &:hover {
                            text-decoration: underline;
                        }

                        @media (max-width: 600px) {
                            padding-left: 1px;
                            padding-right: 1px;
                            font-size: 0.9em;
                        }
                    }
                }
            }

            .socialmedia-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @media (max-width: 600px) {
                    justify-content: center;
                    padding: 5px;
                }

                .socilamedia-icon {
                    a {
                        color: white;
                        font-size: 2em;

                        &:hover {
                            color: #292929;
                        }

                        @media (max-width: 600px) {
                            font-size: 2em;
                        }
                    }
                }
            } //store-footer-social-networks
        } //store-footer-Quick-links
    } //Store-footer-info
}