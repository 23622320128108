.dashboard {
  padding: 0 20px 0 20px;
  min-height: 89vh;
  display: grid;
  grid-template-rows: 10% 1fr;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .week-day {
      width: 45px;
      height: 60px;
      padding: 2px;
      cursor: pointer;

      @include desktop {
        width: 65px;
        height: 75px;
      }

      @media (max-width: 365px) {
        width: 38px;
        height: 50px;
      }

      border: 1px solid transparent;
      background-color: $charcoal;
      color: $off-white;
      border-radius: 5px;
      display: grid;
      grid-template-rows: 1fr 2fr;
      transition: 0.3s ease;

      &:hover {
        opacity: 70%;
      }

      .day {
        font-size: 15px;
        text-align: center;
        @include desktop {
          font-size: 20px;
        }
      }

      .date {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        @include desktop {
          font-size: 30px;
        }
      }
    }

    .checkedIn {
      color: $charcoal;
      background-color: $almost-gold;
    }

    .today {
      color: $almost-gold;
    }

    .checkedIn.today {
      color: white;
    }

    .locked {
      filter: invert(60%);
      &:hover {
        opacity: unset;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .successful-signup-message {
      padding: 0 300px 20px 300px;
      font-size: 18px;

      .download-app-link {
        color: #675dff;
      }
    }
  }
}

.checkin-form {
  display: unset;

  .modal-header {
    padding: 15px;
    width: 100%;
    height: 10%;
    border-bottom: 1px solid black;

    @include desktop {
      height: 15%;
    }

    .modal-title {
      font-size: 25px;
      text-align: justify;
    }

    .modal-close {
      // margin-top: 1px;
      margin-right: 5px;
      position: absolute;
      top: 0;
      right: 0;

      background: transparent;
      border: none;
      font-size: 25px;

      // border: 1px solid black;
      // border-radius: 5px;
      padding: 0px 5px;

      &:hover {
        opacity: 60%;
      }
    }
  }

  .modal-body {
    width: 100%;
    height: 75%;

    @include desktop {
      height: 70%;
    }

    /* spacing as needed */
    padding: 10px;

    /* let it scroll */
    overflow: auto;
  }

  .modal-footer {
    padding: 5px 5px 5px 10px;
    width: 100%;
    height: 15%;

    border-top: 1px solid black;
  }
}

.checkin-body {
  display: grid;
  grid-template-rows: 1fr 8fr;

  .workout-details {
    display: grid;
    grid-template-rows: 1fr 1fr;

    @include desktop {
      grid-template-rows: none;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5px;
    }

    .time-container {
      .time {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        .hours,
        .minutes {
          display: grid;
          grid-template-rows: 1fr 1fr;
        }
      }
    }
  }

  .picture-container {
    height: 200px;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0;
    border-top: 1px solid $off-gray;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      border: 1px solid gray;
      border-radius: 5px;

      padding: 3px;
    }

    .img-preview {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
        align-self: flex-start;

        @include desktop {
          max-width: 400px;
        }
      }
    }
  }

  .time-container,
  .workout-container,
  .picture-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 15px;
    @include desktop {
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr;
      grid-gap: 5px;
    }

    label {
      margin: auto;
      font-size: 20px;
      margin-bottom: 8px;
    }

    select {
      border-radius: 5px;
      border: 1px solid gray;
    }
  }

  .amount {
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
  }
}

.modal-footer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    grid-column: 4;
    height: 60%;
    width: 30%;

    border: 1px solid transparent;
    border-radius: 10px;
    background-color: $almost-gold;
    color: black;

    font-weight: bold;

    margin: auto;

    &:hover {
      opacity: 50%;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}


.message-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .registration-message{
    padding: 15px;
    max-width: 600px;
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  .dashboard {
    padding: 0;
    .body {
      .successful-signup-message {
        padding: 0 10px 20px 10px;
      }
    }
  }
}
