.registration-form {
  margin: 10px;
  margin-bottom: 0;

  min-height: 100vh;

  color: rgb(0, 0, 0);

  @include desktop {
    max-width: 85%;

    margin: auto;

    font-size: 1.5em;

    text-align: center;
  }

  .form-header {
    margin-bottom: 20px;
    text-align: center;

    @include desktop {
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  .slidey-wrapper {
    display: grid;

    @include desktop {
      grid-template-columns: 1fr 1fr;
    }

    .personal-info,
    .payment-info {
      display: grid;
      grid-gap: 2px;
      padding: 0.5em;
      margin: auto;

      width: 90%;

      .title {
        text-align: justify;

        @include desktop {
          text-align: inherit;
        }
      }

      .col {
        justify-self: center;
        width: 80%;

        label {
          font-size: large;
        }
        .labelErr {
          color: red;
        }

        .inputErr {
          border: 1.5px solid red;
        }

        input,
        select {
          width: 100%;
          height: 30px;

          padding: 3px;

          border: none;
          border-radius: 10px;
          margin: 0 auto 15px;
          display: block;

          background-color: #f3f3f4;

          @include desktop {
            height: 2.5em;
            font-size: 1.25rem;
          }
        }

        select {
          font-size: inherit;
        }
      }

      .err {
        display: flex;
        justify-content: center;
        text-align: center;

        height: 21px;
        margin-top: 10px;
        margin-bottom: 10px;

        color: red;
        font-size: 1.1em;
      }

      .totalPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    .payment-info {
      .card-info {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 7px;

        .card-element {
          justify-self: center;
          width: 80%;
          border-bottom: 1px solid black;
        }

        text-align: justify;
      }

      .err {
        display: flex;
        justify-content: center;
        height: 21px;

        margin-top: 20px;
        margin-bottom: 40px;

        text-align: center;
        color: red;
        font-size: 1.1em;
      }

      .totalPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .submit-button {
        display: flex;
        justify-content: center;

        margin: 5px;
        margin-top: 10px;

        button {
          border: 1px solid transparent;
          background-color: black;
          color: #ffffff;
          height: 40px;
          width: 100px;
          transition: 0.3s ease;

          &:hover {
            background-color: grey;
          }

          @include desktop {
            height: 60px;
            width: 150px;
            font-size: inherit;
          }
        }
      }
    }
  }
}
