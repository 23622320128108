.admin {
  height: 90vh;
  width: 100%;
  display: grid;
  grid-template-rows: 8% 1fr;

  @include desktop {
    grid-template-rows: unset;
    grid-template-columns: 8.5% 1fr;
  }

  .sidebar {
    background-color: #1a1a2e;
    color: white;

    @include desktop {
      width: 100%;
    }

    .sidebar-body {
      width: 100%;

      @include desktop {
        flex-direction: column;
      }

      .sidebar-link {
        display: flex;
        justify-content: center;
        cursor: pointer;
        padding: 2px;
        margin-right: 5px;
        border-radius: 5px;

        border-width: 0 2px 2px 2px;
        border-style: solid;
        border-color: whitesmoke;

        @include desktop {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }

      .sidebar-link:hover {
        background-color: grey;
      }

      .selected {
        background-color: grey;
        border-radius: 4px;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .sidebar {
      padding-top: 10px;
      background-color: #1a1a2e;
      color: white;
      white-space: nowrap;
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      @include desktop {
        width: 120px;
      }

      .sidebar-body {
        display: flex;
        text-align: center;
        flex-direction: row;
        align-items: center;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .admin-wrapper {
    width: 100%;
    overflow-x: auto;
    @include desktop {
      margin-left: 0.5rem;
    }

    .admin-table {
      margin: 5px;
    }
    .table {
      width: 100%;
      margin: 0 auto;
      border-collapse: collapse;
      border-radius: 10px;
      overflow: hidden;

      button {
        background-color: #1a1a2e;
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 4px;
      }

      button:hover {
        background-color: #0056b3;
      }

      .table-head {
        background-color: #b3b2b2;
        text-align: left;
      }

      th {
        padding: 5px;
      }

      td {
        padding: 5px;
      }

      tbody tr:nth-child(even) {
        background-color: #f3f2f2;
      }

      tbody tr:hover {
        background-color: #cccccc;
      }
    }
  }
}

.user_admin {
  margin: 25px;
  width: 95%;
  // height: 650px;
  background-color: white;
  border: 1px solid #bac4ce;
  border-radius: 3px;

  .user_admin-header {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #bac4ce;

    .user_admin-headerLeft {
      width: 40%;
    }

    .user_admin-headerRight {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }

    .user_admin-headerTitle {
      font-size: 24px;
      font-weight: 600;
    }

    .user_admin-headerSearch,
    .user_admin-headerOrder,
    .user_admin-headerActions {
      border: 1px solid #bac4ce;
      border-radius: 3px;
      width: 30%;
    }

    .user_admin-headerActions {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .user_admin-headerSearch:focus {
      outline: none;
    }
  }

  .user_admin-body {
    .user_admin-table {
      .user_admin-tableHeader {
        display: grid;
        grid-template-columns: 100px 150px 150px 1fr 100px 100px 100px;

        p {
          background-color: #bac4ce;
          // width: 100%;
          height: 25px;
          text-align: center;
        }

        p:nth-child(1) {
          // width: 20%;
        }
      }

      .user_admin-tableBody {
        .row {
          display: grid;
          grid-template-columns: 100px 150px 150px 1fr 100px 100px 100px;

          p {
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: center;
          }
        }
        .row:nth-child(even) {
          background-color: $White;
        }
      }
    }
  }
}

.filterDropdown {
  font-size: 18px;
  margin: 10px;
  border-radius: 8px;
  background-color: #6f8295;
  color: white;
}

.filter-label {
  font-size: 18px;
}

@media (max-width: 800px) {
  .filterDropdown {
    font-size: 13px;
    padding: 1px;
    width: 50%;
  }

  .filters-menu{
    display: flex;
    flex-direction: column;
  }
}
