.score-table {
  width: 50%;
  margin: 0 auto;
  border-radius: 10px;
  padding-bottom: 20px;
}

.score-table-head th{
  text-align: start;
  background-color: #b3b2b2;
  padding: 5px;
}

.tbody tr:nth-child(even){
  background-color: #f3f2f2;
}

.tbody tr:hover {
  background-color: #cccccc;
}

.tbody td {
  padding: 5px;
}

@media (max-width: 470px) {
  .score-table {
    margin: auto;
    width: 80%;
  }
}
