.Button {
    display:flex;
    justify-content: space-evenly;
    align-items:center;

    button{
        background:rgba(57, 51, 51, 0.563);
       border-radius: 10px;
    }
}

.profile-icon{
    font-size: 30px;
    transition: 0.2s;
    color: #a3aed0;
    &:hover{
        transform: scale(1.2);
        color:white
    }

    @media (max-width:800px){
        display: none;
    }

   
}